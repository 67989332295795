import './App.css';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import Webcam from "react-webcam";




const disp = () => {

  const first = document.querySelector('.camera');
  const formDis = document.querySelector('.form');
  const btnBack = document.querySelector('.btn-back');
  const congrats = document.querySelector('.cong');

  first.classList.remove('op');
  formDis.classList.remove('op');
  congrats.classList.add('op');
  btnBack.textContent = "View Photos";




}
const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: "user"
};
const images = [];
const WebcamCapture = () => {
  const webcamRef = React.useRef(null);
  const [imageSrc, setImgSrc] = React.useState(null);

  const capture = React.useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      setImgSrc(imageSrc);
      images.push(imageSrc);


      const first = document.querySelector('.camera');
      const formDis = document.querySelector('.form');
      const btnBack = document.querySelector('.btn-back');
      const congrats = document.querySelector('.cong');


      first.classList.add('op');
      formDis.classList.add('op');
      congrats.classList.remove('op');



      btnBack.textContent = "Capture More Photos";


    },
    [webcamRef, setImgSrc]

  );

  //Encoding Image Links and storing them in another array
  let encodedImg = images.map(btoa);
  console.log(encodedImg);

  localStorage.setItem('img', JSON.stringify(encodedImg));

  const decoded = JSON.parse(localStorage.getItem('img')).map(atob);
  console.log(decoded);

  const photos = () => {
    (
      <>
        {decoded.map(pho => (
          <div>
            <img src={pho} />
          </div>
        ))}
      </>
    )
  };



  return (
    <>
      <div className='camera'>
        <Webcam
          audio={false}
          height={420}
          ref={webcamRef}
          screenshotFormat="image/jpg"
          width={420}
          videoConstraints={videoConstraints}
        />
        <button onClick={capture} className="btn-capture">Capture photo</button>
        {/* {imageSrc && <img src={imageSrc} />} */}
      </div>

      <div className='second'>
        <button onClick={disp} className="btn-back view con" >View Photos</button>
        <div className='cong op'>
          <div className='msg'>Conratulation!! Your Photos have been Captured!</div>
          <div className='images'>
            <img src={decoded[0]} />
            <img src={decoded[1]} />
            <img src={decoded[2]} />
            <img src={decoded[3]} />
          </div>
        </div>
      </div>
    </>
  );
};
function App() {
  return (
    WebcamCapture()
  );
}




export default App;
